import { toast } from "react-toastify";
import internalApi from "../../config/internalApi";
import { createAction } from "../utils";
import { REMOVE_CARDS } from "./cards";
import { REMOVE_REFERRALS } from "./referrals";
import { initRunDependenciesIfAllowed } from "../../containers/externaldependenciesoptout/runDependenciesIfAllowed";
import { setupExternalServicesWithUser } from "../../containers/AppUtils";

const LOGIN = "plt-web/auth/LOGIN";
const GET_CUSTOMER_INFO = "plt-web/auth/GET_CUSTOMER_INFO";
const LOGOUT = "plt-web/auth/LOGOUT";
export const TOGGLE_LOGIN_MODAL = "plt-web/auth/TOGGLE_LOGIN_MODAL";

const initialState = {
    user: {},
    isAuthenticated: false,
    loginModal: false
};

const toLoggedUser = (loginPayload) => {
    const { name, email, sub, phone } = loginPayload;
    const firstName = name.replace(/ .*/, "");
    const capitalisedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);

    const loggedUser = {
        id: sub,
        email,
        firstName: capitalisedFirstName,
        phone
    };
    return loggedUser;
};

export default function reducer(state = initialState, { type, ...action }) {
    switch (type) {
        case LOGIN: {
            const loggedUser = toLoggedUser(action.payload);

            toast.success(`Welcome back, ${loggedUser.firstName}!`);

            const runDependenciesIfAllowed = initRunDependenciesIfAllowed({
                localStorage: window.localStorage,
                locationHref: window.location.href
            });
            runDependenciesIfAllowed(() => {
                setupExternalServicesWithUser(loggedUser);
            });

            return {
                user: loggedUser,
                isAuthenticated: true,
                loginModal: false
            };
        }
        case LOGOUT: {
            toast.success("You've been successfully logged out!");
            return initialState;
        }
        case GET_CUSTOMER_INFO: {
            const { id, customerIdentifier, firstName, ...customer } = action.payload;
            return {
                ...state,
                user: {
                    ...state.user,
                    ...customer
                }
            };
        }
        case TOGGLE_LOGIN_MODAL: {
            return {
                ...state,
                loginModal: !state.loginModal
            };
        }
        default:
            return state;
    }
}

export const loginUser = (params) => async (dispatch) =>
    internalApi.post("/v2/auth/login", params).then((response) => {
        dispatch(createAction(LOGIN, response));
        return Promise.resolve(response);
    });

export const logoutUser = () => (dispatch) => {
    return internalApi.post("/v2/auth/logout").then((response) => {
        localStorage.clear();

        dispatch(createAction(REMOVE_CARDS));
        dispatch(createAction(REMOVE_REFERRALS));
        dispatch(createAction(LOGOUT, ""));

        return Promise.resolve(response);
    });
};

export const getCustomerInfo = () => (dispatch) => {
    internalApi.get("/v2/customer/me", {}).then((response) => {
        dispatch(createAction(GET_CUSTOMER_INFO, response));
    });
};
