import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
    setupExternalServices,
    setupExternalServicesWithUser,
} from "../AppUtils";
import { initRunDependenciesIfAllowed } from "../externaldependenciesoptout/runDependenciesIfAllowed";
import { updateSiteLang } from "../../utils/SiteUtils";

export const WithTracking = ({ children }) => {
    const user = useSelector((state) => state.auth);

    const runDependenciesIfAllowed = initRunDependenciesIfAllowed({
        localStorage: window.localStorage,
        locationHref: window.location.href
    });

    useEffect(() => {
        updateSiteLang();
        runDependenciesIfAllowed(() => {
            setupExternalServices();
        });
    }, []);

    useEffect(() => {
        runDependenciesIfAllowed(() => {
            setupExternalServicesWithUser(user.user);
        });
    }, [user]);

    return (
        <>
            {children}
        </>
    );
}