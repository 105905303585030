export const initRunDependenciesIfAllowed = ({ localStorage, locationHref }) => {
  return (callback) => {
    if (localStorage) {
        // Did this request enabled opt-out?
        if (locationHref.indexOf("ext-svc-opt-out") !== -1) {
            localStorage.setItem("ext-svc-opt-out", '1');
        }
        // Does not run callback if opted out
        const allowsExternalServices = !localStorage.getItem("ext-svc-opt-out");
        if (allowsExternalServices) {
            callback();
        }
    }
  }
}