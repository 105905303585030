import axios from 'axios';
import { API_URL } from './index';

const http = axios.create({
    baseURL: API_URL,
    withCredentials: true,
});

http.interceptors.response.use(
    (response) => {
        if (response.status >= 200 && response.status < 300) {
            console.log(`Response Body (${response.status}):`, response.data);
        }
        return response;
    },
    (error) => {
        const { response } = error;

        if (response) {
            // Log client error responses (400 status codes)
            if (response.status >= 400) {
                console.log(`Response Body (${response.status}):`, response.data);
            }
        } else {
            // Log network or other errors
            console.log('Error:', error.message);
        }
        return Promise.reject(error);
    },
);

const formatUrl = (url) => `${url}`

const defaultHeaders = () => {
    const timezoneOffset = new Date().getTimezoneOffset() * -1;
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Timezone-Offset': timezoneOffset,
        'X-Amplitude-User-Id': window.amplitude?.getUserId(), // FIXME: now that the domains line up, amplitude cookies are supposed to be sent with every request; these headers can then be removed.
        'X-Amplitude-Device-Id': window.amplitude?.getDeviceId(),
    };
}

class InternalApi {
    static get(urlPath, params = {}, headers = {}) {
      const requestParams = {
        params,
        headers: { ...headers, ...defaultHeaders(), },
      };
      return http.get(formatUrl(urlPath), requestParams).then(
        (res) => res.data
      );
    }

    static post(urlPath, data = {}, headers = {}) {
      const requestParams = {
        headers: { ...headers, ...defaultHeaders() },
      };

      return http.post(formatUrl(urlPath), data, requestParams).then(
        (res) => res.data
      );
    }

    static put(urlPath, data = {}, headers = {}) {
      const requestParams = {
        headers: { ...headers, ...defaultHeaders() },
      };

      return http.put(formatUrl(urlPath), data, requestParams).then(
        (res) => res.data
      );
    }
}

export default InternalApi;
