import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { getSiteValueByLang } from "../utils/SiteUtils";
export const setupExternalServices = () => {
    setAmplitude(null);
    setGoogleAnalytics();
};
export const setupExternalServicesWithUser = (user) => {
    const { id: userId, email: userEmail, firstName: userName, phone: userPhone } = user;
    setAmplitude(userId);
    setTagManager(userId, userEmail, userName, userPhone);
    setDataDog(userId, userEmail);
};
const setAmplitude = (userId) => {
    if (window.amplitude) {
        window.amplitude.setUserId(userId);
    }
};
const setGoogleAnalytics = () => {
    const gaMeasurementId = getSiteValueByLang("gaMeasuremntId");
    ReactGA.initialize(gaMeasurementId);
};
const setDataDog = (userId, userEmail) => {
    datadogLogs.init({
        clientToken: 'pubfe3de757580b6543d9d5412bb7f0fb22',
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['log', 'error'],
        sessionSampleRate: 100,
        service: 'plt-web',
        env: process.env.REACT_APP_ENVIRONMENT
    })

    datadogRum.init({
        applicationId: "1e8e61a0-eada-4ad5-a6ae-8dc5ebfa8fed",
        clientToken: "pubfe3de757580b6543d9d5412bb7f0fb22",
        site: "datadoghq.com",
        service: "plt-web",
        env: process.env.REACT_APP_ENVIRONMENT,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input"
    });

    datadogRum.setUser({
        id: userId,
        email: userEmail
    });

    datadogRum.startSessionReplayRecording();
};
const setTagManager = (userId, userEmail, userName, userPhone) => {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        auth: process.env.REACT_APP_GTM_ENV_AUTH,
        preview: process.env.REACT_APP_GTM_ENV_PREVIEW,
        dataLayer: {
            user: {
                id: userId,
                email: userEmail,
                name: userName,
                phone: userPhone
            },
            nodeEnv: process.env.NODE_ENV
        }
    };
    TagManager.initialize(tagManagerArgs);
};
